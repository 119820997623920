import React from 'react';
import './cta-footer.styles.scss';

const CTAFooterTextComponent = () => (
    <>
        <h2>Call us today</h2>
        <p>
            For any urologic condition, you can make an appointment at the location of your choice by calling
            <br/><br/>
            <a href='tel:+17028770814'>1-702-877-0814</a>, <b>TTY 711</b>
            <br/><br/>
            Monday–Friday, 8 a.m–5 p.m.
        </p>
    </>
);
export default CTAFooterTextComponent;
